<template>
  <div class="keyBoardContainer">
    <van-popup
      v-model="show"
      position="bottom"
      :overlay="showOverlay"
      :style="{background:'#BBC2CA'}"
      :safe-area-inset-bottom="true"
    >
      <span class="clearInput" @click="clearInput">清空</span>
      <span class="complete" @click="inputComplete">完成</span>
      <div class="keyboard">
        <div v-if="showCnKeyBoard" class="keyboard_content">
          <span @click="onKeyBoardPress" data-text="京">京</span>
          <span @click="onKeyBoardPress" data-text="津">津</span>
          <span @click="onKeyBoardPress" data-text="冀">冀</span>
          <span @click="onKeyBoardPress" data-text="鲁">鲁</span>
          <span @click="onKeyBoardPress" data-text="晋">晋</span>
          <span @click="onKeyBoardPress" data-text="蒙">蒙</span>
          <span @click="onKeyBoardPress" data-text="辽">辽</span>
          <span @click="onKeyBoardPress" data-text="吉">吉</span>
          <span @click="onKeyBoardPress" data-text="黑">黑</span>
          <span @click="onKeyBoardPress" data-text="沪">沪</span>
          <span @click="onKeyBoardPress" data-text="苏">苏</span>
          <span @click="onKeyBoardPress" data-text="浙">浙</span>
          <span @click="onKeyBoardPress" data-text="皖">皖</span>
          <span @click="onKeyBoardPress" data-text="闽">闽</span>
          <span @click="onKeyBoardPress" data-text="赣">赣</span>
          <span @click="onKeyBoardPress" data-text="豫">豫</span>
          <span @click="onKeyBoardPress" data-text="鄂">鄂</span>
          <span @click="onKeyBoardPress" data-text="湘">湘</span>
          <span @click="onKeyBoardPress" data-text="粤">粤</span>
          <span @click="onKeyBoardPress" data-text="桂">桂</span>
          <span @click="onKeyBoardPress" data-text="渝">渝</span>
          <span @click="onKeyBoardPress" data-text="川">川</span>
          <span @click="onKeyBoardPress" data-text="贵">贵</span>
          <span @click="onKeyBoardPress" data-text="云">云</span>
          <span @click="onKeyBoardPress" data-text="藏">藏</span>
          <span @click="onKeyBoardPress" data-text="陕">陕</span>
          <span @click="onKeyBoardPress" data-text="甘">甘</span>
          <span @click="onKeyBoardPress" data-text="青">青</span>
          <span @click="onKeyBoardPress" data-text="琼">琼</span>
          <span @click="onKeyBoardPress" data-text="新">新</span>
          <span class="normalWidth change" @click="changeKeyBoard">ABC</span>
          <span @click="onKeyBoardPress" data-text="宁">宁</span>
          <span @click="onKeyBoardPress" data-text="港">港</span>
          <span @click="onKeyBoardPress" data-text="澳">澳</span>
          <span @click="onKeyBoardPress" data-text="台">台</span>
          <span @click="onKeyBoardPress" data-text="警">警</span>
          <span @click="onKeyBoardPress" data-text="领">领</span>
          <span @click="onKeyBoardPress" data-text="使">使</span>
          <span @click="onKeyBoardPress" data-text="临">临</span>
          <span class="normalWidth delete" @click="delCarlicense">
            <img src="@/assets/image/del.png" alt />
          </span>
        </div>
        <div v-else class="keyboard_content">
          <span @click="onKeyBoardPress" data-text="1">1</span>
          <span @click="onKeyBoardPress" data-text="2">2</span>
          <span @click="onKeyBoardPress" data-text="3">3</span>
          <span @click="onKeyBoardPress" data-text="4">4</span>
          <span @click="onKeyBoardPress" data-text="5">5</span>
          <span @click="onKeyBoardPress" data-text="6">6</span>
          <span @click="onKeyBoardPress" data-text="7">7</span>
          <span @click="onKeyBoardPress" data-text="8">8</span>
          <span @click="onKeyBoardPress" data-text="9">9</span>
          <span @click="onKeyBoardPress" data-text="0">0</span>
          <span @click="onKeyBoardPress" data-text="Q">Q</span>
          <span @click="onKeyBoardPress" data-text="W">W</span>
          <span @click="onKeyBoardPress" data-text="E">E</span>
          <span @click="onKeyBoardPress" data-text="R">R</span>
          <span @click="onKeyBoardPress" data-text="T">T</span>
          <span @click="onKeyBoardPress" data-text="Y">Y</span>
          <span @click="onKeyBoardPress" data-text="U">U</span>
          <span @click="onKeyBoardPress" data-text="I">I</span>
          <span @click="onKeyBoardPress" data-text="O">O</span>
          <span @click="onKeyBoardPress" data-text="P">P</span>
          <span @click="onKeyBoardPress" data-text="A">A</span>
          <span @click="onKeyBoardPress" data-text="S">S</span>
          <span @click="onKeyBoardPress" data-text="D">D</span>
          <span @click="onKeyBoardPress" data-text="F">F</span>
          <span @click="onKeyBoardPress" data-text="G">G</span>
          <span @click="onKeyBoardPress" data-text="H">H</span>
          <span @click="onKeyBoardPress" data-text="J">J</span>
          <span @click="onKeyBoardPress" data-text="K">K</span>
          <span @click="onKeyBoardPress" data-text="L">L</span>
          <span class="change" @click="changeKeyBoard">ABC</span>
          <span @click="onKeyBoardPress" data-text="Z">Z</span>
          <span @click="onKeyBoardPress" data-text="X">X</span>
          <span @click="onKeyBoardPress" data-text="C">C</span>
          <span @click="onKeyBoardPress" data-text="V">V</span>
          <span @click="onKeyBoardPress" data-text="B">B</span>
          <span @click="onKeyBoardPress" data-text="N">N</span>
          <span @click="onKeyBoardPress" data-text="M">M</span>
          <span class="delete" @click="delCarlicense">
          <img src="@/assets/image/del.png" alt="" />
          </span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    showKeyBoard: {
      type: Boolean,
      required: true
    },
    showCNKeyBoard: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      show: this.showKeyBoard,
      showOverlay: false,
      showCnKeyBoard: this.showCNKeyBoard
    };
  },

  mounted() {},

  computed: {},

  watch: {
    showKeyBoard: function(val) {
      this.show = val;
    },
    showCNKeyBoard: function(val) {
      this.showCnKeyBoard = val;
    }
  },

  methods: {
    onKeyBoardPress(e) {
      this.$emit("key-press", e.target.innerHTML);
    },
    changeKeyBoard() {
      this.$emit("change-press");
    },
    delCarlicense() {
      this.$emit("del-press");
    },
    inputComplete() {
      this.$emit("input-over");
    },
    clearInput() {
      this.$emit("clear-press");
    }
  }
};
</script>

<style scoped lang="scss">
.keyBoardContainer {
  font-family: PingFangSC-Regular, sans-serif;
  color: #030303;
  .complete {
    display: block;
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 36px;
    line-height: 64px;
    color: #0078ff;
  }
  .clearInput {
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 36px;
    line-height: 64px;
    color: #0078ff;
  }
  .keyboard {
    position: relative;
    background: rgb(206, 213, 221);
    margin-top: 64px;
  }
  .keyboard_content {
    display: flex;
    justify-content: center;
    flex-flow: row;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .keyboard_content span {
    display: block;
    text-align: center;
    width: 64px;
    height: 84px;
    line-height: 84px;
    font-size: 40px;
    background: white;
    padding-left: 0;
    padding-right: 0;
    margin: 10px 5px;
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.35);
  }
  .keyboard_content .change,
  .keyboard_content .delete {
    font-size: 32px;
    width: 96px;
    background: #abb3bd;
  }
  .keyboard_content .normalWidth {
    width: 64px;
  }
  .keyboard_content .delete img {
    width: 52px;
    height: 40px;
    margin-top: 22px;
  }
}
</style>
