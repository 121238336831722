<template>
  <div class="container">
    <div class="page_top">
      <div class="pay_icon">
        <img src="@/assets/image/paysuc.png" alt />
      </div>
      <div class="pay_desc">
        支付成功!
      </div>
      <van-button class="complete" @click="complete" type="primary"
        >完成</van-button
      >
    </div>
  </div>
</template>

<script>
import { closeWebviewEvent } from "@/utils/common";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    complete() {
      closeWebviewEvent();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // pushHistory();
    window.addEventListener("popstate", closeWebviewEvent, false);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  destroyed() {
    window.removeEventListener("popstate", closeWebviewEvent, false);
  }
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.container {
  position: relative;
  height: 100vh;
  text-align: center;
  background: #fff;
  font-size: 28px;
  .page_top {
    padding: 200px 0;
    background: #fff;
    .pay_icon {
      img {
        width: 300px;
      }
    }
    .pay_desc {
      text-align: center;
      margin-top: 20px;
      font-size: 36px;
      font-weight: bold;
      color: #535353;
    }
  }

  .complete {
    width: 360px;
    height: 80px;
    background: linear-gradient(-67deg, #26a0a5, #8de9c6);
    border-radius: 40px;
    border: none;
    margin-top:100px;
  }
}
</style>