<!-- 绑定的车辆列表 -->
<template>
  <div v-if="showPage" class="container">
    <div class="fillhigh"></div>
    <div class="topinfo-fixd">
      <div class="topinfo">
        <img class="bjimg" src="@/assets/image/bj.png" alt="" />
        <div class="carinfo">
          <div class="carnum">{{ allPrice.incomeName }}</div>
          <div class="cardesc">您好，该车辆已被停车场管理员设置为黑名单。</div>
          <div class="cardaddress">
            <img src="@/assets/image/address_icon.png" alt="" />
            <span>{{ allPrice.parkinglotName }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="orderlist && orderlist.length > 0">
      <div class="orderlist">
        <div class="oltitle">未缴记录</div>
        <div class="olist">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            offset="50"
            @load="onloadList"
          >
            <div class="olrow" v-for="(item, index) in orderlist" :key="index">
              <div class="olrowleft">
                <div class="oltime">{{ item.parkingTime }}</div>
                <div class="oldesc">{{ item.payTime }}</div>
              </div>
              <div class="olrowright">
                <div class="olprice">￥{{ item.difference }}</div>
                <div class="oldesc">待缴费</div>
              </div>
            </div>
          </van-list>
        </div>
      </div>

      <div class="paybottom">
        <div class="alldesc">
          未缴记录：{{ allPrice.totalRows }}条，共计欠费￥{{
            allPrice.sumMoney
          }}。
        </div>
        <div class="alldescbtn" @click="payparam">
          ￥{{ allPrice.sumMoney }} 支付
        </div>
      </div>
    </div>
    <div class="yesnull" v-else>
      <img class="qfimg" src="@/assets/image/qianfei.png" alt="" />
      <div class="qfdesc">未找到欠费记录</div>
      <div class="dhdesc">请联系停车场管理员或拨打一下号码</div>
      <div class="dhnum">
        <img class="dhimg" src="@/assets/image/dianhua.png" alt="" />
        <span>028-89990000</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCarOrder,
  getCarOrderSum,
  blacklistPay,
  getOrderParkCode,
  getOrderSumParkCode
} from "@/utils/api";
import { Notify, Toast } from "vant";
import { JudgeUA } from "@/utils/common";
export default {
  data() {
    return {
      scanAuthInfo: null,
      showPage: true,
      orderlist: [],
      currentPage: 1,
      pageSize: 8,
      loading: false,
      finished: false,
      queryData: {},
      payData: {
        incomeName: "补缴停车费",
        amountDiscount: 0
      },
      allPrice: {},

      parkinglotCode: ""
    };
  },
  computed: {},
  watch: {},
  filters: {},
  methods: {
    getorderList() {
      var that = this;
      var param = {
        incomeName: that.queryData.license,
        parkinglotId: that.queryData.parkinglotId,
        currentPage: that.currentPage,
        pageSize: that.pageSize
      };
      getCarOrder(param)
        .then(result => {
          if (result.status == "200" || result.status == 200) {
            that.showPage = true;
            if (result.data && result.data.length < 1) {
              that.loading = false;
              that.finished = true;
            } else {
              const newOrderList = [...that.orderlist, ...result.data];
              that.orderlist = newOrderList;
              that.currentPage = that.currentPage + 1;
              that.loading = false;
            }
          } else {
            Notify({ type: "danger", message: result.message });
            that.loading = false;
            that.finished = true;
          }
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    getAllPrice() {
      var that = this;
      var param = {
        incomeName: that.queryData.license,
        parkinglotId: that.queryData.parkinglotId
      };
      getCarOrderSum(param)
        .then(result => {
          that.allPrice = result.data;
          if (result.status == "200") {
            that.payData["amountTotal"] = result.data.sumMoney;
            that.payData["amountCurrentPay"] = result.data.sumMoney;
          } else {
            Notify({ type: "danger", message: result.message });
            that.loading = false;
            that.finished = true;
          }
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    payparam() {
      const ua = JudgeUA();
      switch (ua) {
        case "wechat":
          this.payData["sceneType"] = "102A" + this.queryData.payType;
          break;
        case "alipay":
          this.payData["sceneType"] = "103B" + this.queryData.payType;
          break;
        default:
      }

      window.console.log(this.payData);
      this.xpay(this.payData);
    },
    xpay(param) {
      var that = this;
      blacklistPay(param)
        .then(result => {
          window.console.log(result);
          if (result.status == 200 || result.status == "200") {
            this.pullupPay(result.data);
          } else {
            Notify({ type: "danger", message: result.message });
          }
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    pullupPay(data) {
      const ua = JudgeUA();
      switch (ua) {
        case "wechat":
          if (data.payUrl) {
            window.location.href = data.payUrl;
          } else {
            this.wechatPayAction(data.jsApiObject);
          }
          break;
        case "alipay":
          window.location.href = data.payUrl;
          break;
        default:
      }
    },
    wechatPayAction(obj) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        this.onBridgeReady(obj);
      }
    },

    onBridgeReady(obj) {
      Toast.clear();
      let that = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: obj.appId,
          timeStamp: obj.timeStamp,
          nonceStr: obj.nonceStr,
          package: obj.package,
          signType: obj.signType,
          paySign: obj.paySign
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            that.$router.push({
              path: "/paySuc"
            });
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            window.console.log("用户取消支付");
            Toast("已取消支付");
          } else {
            Notify({ type: "danger", message: res.err_msg });
          }
        }
      );
    },
    getorderCodeList() {
      var that = this;
      var param = {
        incomeName: that.queryData.carlicense,
        parkinglotCode: that.queryData.parkinglotCode,
        currentPage: that.currentPage,
        pageSize: that.pageSize
      };
      getOrderParkCode(param)
        .then(result => {
          if (result.status == "200" || result.status == 200) {
            that.showPage = true;
            if (result.data && result.data.length < 1) {
              that.loading = false;
              that.finished = true;
            } else {
              const newOrderList = [...that.orderlist, ...result.data];
              that.orderlist = newOrderList;
              that.currentPage = that.currentPage + 1;
              that.loading = false;
            }
          } else {
            Notify({ type: "danger", message: result.message });
            that.loading = false;
            that.finished = true;
          }
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    getAllCodePrice() {
      var that = this;
      var param = {
        incomeName: that.queryData.carlicense,
        parkinglotCode: that.queryData.parkinglotCode
      };
      getOrderSumParkCode(param)
        .then(result => {
          that.allPrice = result.data;
          if (result.status == "200") {
            that.payData["amountTotal"] = result.data.sumMoney;
            that.payData["amountCurrentPay"] = result.data.sumMoney;
          } else {
            Notify({ type: "danger", message: result.message });
            that.loading = false;
            that.finished = true;
          }
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    onloadList() {
      if (this.queryData.iscode) {
        this.getorderCodeList();
        this.getAllCodePrice();
      } else {
        this.getAllPrice();
        this.getorderList();
      }
    }
  },
  created() {
    const obj = this.$route.query;
    window.console.log("最终参数：");
    window.console.log(obj);
    this.queryData = obj;
    this.scanAuthInfo = JSON.parse(localStorage.getItem('scanAuthInfo'))
    if (obj.iscode) {
      this.payData["dataId"] = obj.carlicense;
      this.payData["parkingLotId"] = obj.parkinglotId;
      this.payData["carLicense"] = obj.carlicense;
    } else {
      this.payData["parkingLotId"] = obj.parkinglotId;
      this.payData["drivewayId"] = obj.drivewayId;
      this.payData["dataId"] = obj.license;
      this.payData["carLicense"] = obj.license;
    }

    if (obj.openId) {
      this.payData["openId"] = obj.openId;
      this.payData["unionId"] = obj.unionId;
    }

    if (obj.iscode) {
      this.getorderCodeList();
      this.getAllCodePrice();
      console.log("......1");
    } else {
      this.getAllPrice();
      this.getorderList();
      console.log("......2");
    }
  },
  mounted() {},
  destroyed() {
    Notify.clear();
  }
};
</script>
<style lang='scss' scoped>
.container {
  background: #ffffff;
  height: 100vh;
  width: 100%;
  .topinfo-fixd {
    position: fixed;
    z-index: 1;
    height: 460px;
    width: 100%;
    top: 0;
    background: #ffffff;
  }
  .fillhigh {
    height: 460px;
    width: 100%;
  }
  .topinfo {
    position: relative;
    height: 460px;
    .bjimg {
      width: 100%;
      height: 260px;
    }
    .carinfo {
      min-height: 300px;
      background: #ffffff;
      box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
      border-radius: 12px;
      width: 90%;
      position: absolute;
      top: 100px;
      left: 50%;
      margin-left: -45%;
      text-align: center;
      .carnum {
        font-size: 32px;
        color: #26a0a5;
        height: 100px;
        line-height: 100px;
        padding-top: 30px;
      }
      .cardesc {
        font-size: 28px;
        color: #414141;
        margin-bottom: 30px;
      }
      .cardaddress {
        font-size: 24px;
        color: #666666;
        span {
          line-height: 40px;
          padding-top: -20px;
        }
        img {
          width: 18px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }
  }

  .orderlist {
    padding: 0 30px;
    margin-bottom: 220px;
    .oltitle {
      height: 48px;
      line-height: 48px;
      font-size: 28px;
      padding-left: 10px;
      color: #3c3a3a;
      border-left: 4px solid #26a0a5;
    }
    .olrow {
      position: relative;
      border-bottom: 2px solid #b5b5b5;
      padding: 20px 0;
    }
    .oltime {
      font-size: 28px;
      color: #222222;
    }
    .oldesc {
      font-size: 24px;
      color: #a2a2a2;
      margin-top: 10px;
    }
    .olprice {
      font-size: 32px;
      color: #ff2b2b;
    }
    .olrowleft {
      padding-left: 10px;
    }
    .olrowright {
      position: absolute;
      top: 10px;
      right: 10px;
      text-align: right;
    }
  }
  .paybottom {
    text-align: center;
    position: fixed;
    z-index: 11;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding-bottom: 40px;

    .alldesc {
      font-size: 28px;
      color: #ff7f00;
      padding-top: 20px;
      height: 80px;
      line-height: 80px;
      font-weight: 600;
    }
    .alldescbtn {
      width: 600px;
      height: 80px;
      line-height: 80px;
      background: linear-gradient(-67deg, #26a0a5, #8de9c6);
      border-radius: 4px;
      color: #ffffff;
      font-size: 28px;
      margin: 0 auto;
    }
  }

  .yesnull {
    text-align: center;
    padding-top: 40px;
    .qfimg {
      width: 300px;
    }
    .dhimg {
      width: 50px;
      height: 50px;
      padding-right: 10px;
    }
    .qfdesc {
      font-size: 24px;
      color: #1b1b1b;
      opacity: 0.5;
      margin: 20px auto;
    }
    .dhdesc {
      font-size: 24px;
      color: #666666;
      margin: 50px auto 20px auto;
    }
    .dhnum {
      font-size: 36px;
      color: #ff7f00;
      height: 60px;
      line-height: 60px;
      span,
      img {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
</style>