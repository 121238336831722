<template>
  <div id="scanInside">
    <div class="wrap">
      <div class="search_title">{{ title }}</div>
      <div class="new_energy_car">
        <span @click="showCnKeyBoard">{{
          carlicenseArr[0] ? carlicenseArr[0] : ""
        }}</span>
        <span @click="showKeyBoard">{{
          carlicenseArr[1] ? carlicenseArr[1] : ""
        }}</span>
        <span @click="showKeyBoard">{{
          carlicenseArr[2] ? carlicenseArr[2] : ""
        }}</span>
        <span @click="showKeyBoard">{{
          carlicenseArr[3] ? carlicenseArr[3] : ""
        }}</span>
        <span @click="showKeyBoard">{{
          carlicenseArr[4] ? carlicenseArr[4] : ""
        }}</span>
        <span @click="showKeyBoard">{{
          carlicenseArr[5] ? carlicenseArr[5] : ""
        }}</span>
        <span @click="showKeyBoard">{{
          carlicenseArr[6] ? carlicenseArr[6] : ""
        }}</span>
        <span v-if="newEnergyCar" @click="showKeyBoard" style="color:#16AF99">{{
          carlicenseArr[7] ? carlicenseArr[7] : ""
        }}</span>
        <span v-else style="color:#16AF99" @click="changeCarType">新</span>
      </div>

      <van-button
        class="search"
        :loading="loading"
        :loading-text="loadingText"
        :disabled="disabled"
        @click="findOrder"
        type="primary"
        size="large"
        color="#16AF99"
        >查询缴费信息</van-button
      >

      <div class="history_search_wrap">
        <div class="history_title">
          历史搜索
          <div @click="clearHistory">
            <img src="@/assets/image/clear.png" />
            <!-- <span>清空</span> -->
          </div>
        </div>
        <div
          class="history_search"
          v-if="historyCars && historyCars.length > 0"
        >
          <div v-for="item in historyCars" :key="item">
            <div class="historyCar_item" @click="findHistoryCarOrder">
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="myCars && myCars.length > 0" class="mycars_search_wrap">
        <div class="history_title">
          我的车牌
          <!-- <div @click="clearHistory">
            <img src="@/assets/image/clear.png" />
            <span>清空</span>
          </div>-->
        </div>
        <div class="history_search">
          <div v-for="item in myCars" :key="item.bindingId">
            <div class="historyCar_item" @click="findHistoryCarOrder">
              {{ item.carLicense }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <KeyBoard
      :showKeyBoard="show"
      :showCNKeyBoard="showCn"
      v-on:input-over="hideKeyBoard"
      v-on:change-press="changeKeyBoard"
      v-on:key-press="getPressKeyValue"
      v-on:del-press="delCarKeyPress"
      v-on:clear-press="clearCarInput"
    />
  </div>
</template>

<script>
import KeyBoard from "@/components/KeyBoard"
import { Notify } from "vant";
import {
  scanApp,
  closeWebviewEvent,
  uuid,
  wxPublic
} from "@/utils/common";
import { apiUserInfo, getCarTypeByParkingCode } from "@/utils/api";

export default {
  data() {
    return {
      scanAuthInfo: null,
      show: true,
      showCn: true,
      loading: false,
      loadingText: "",
      disabled: true,
      carLength: 7,
      carlicenseArr: [],
      newEnergyCar: false,
      historyCars: [],
      myCars: [],
      param: null,
      recieveP: null,
      unionId: "",
      title: "请输入车牌查询"
    };
  },
  components: {
    KeyBoard
  },
  created() {
    this.scanAuthInfo = JSON.parse(localStorage.getItem('scanAuthInfo'))
    if (this.carlicenseArr.length == 0) {
      this.showCn = true;
    } else if (this.carlicenseArr.length == 1) {
      this.showCn = false;
    }
    window.addEventListener("popstate", closeWebviewEvent, false);

    let that = this;

    const cars = localStorage.getItem("historyCars");
    this.historyCars = cars ? cars.split(",") : [];

    const obj = that.$route.query;
    const recieveP = obj.p.split("-");
    this.recieveP = recieveP;
    console.log(obj);
    if (obj.code) {
      const getUnionIdP = {
        p: wxPublic(),
        tokenCode: obj.code
      };

      apiUserInfo(getUnionIdP)
        .then(result => {
          console.log(result);
          if (result.status == "success") {
            that.unionId = result.data.unionid;
            that.openId = result.data.openid;
          } else {
            Notify({ type: "danger", message: "获取用户信息失败，请重新扫码" });
          }
        })
        .catch(err => {
          window.console.log(err);
        });
    }
  },
  mounted() {
    this.set_screen("#wxInside");
  },
  destroyed() {
    window.removeEventListener("popstate", closeWebviewEvent, false);
    Notify.clear();
  },
  watch: {
    carlicenseArr: function() {
      const len = this.carlicenseArr.length;
      len == 0 && (this.showCn = true);
      len == 1 && (this.showCn = false);
      len > 6 && (this.disabled = false);
      len < 7 && (this.disabled = true);
      if (len < 7) {
        this.title = "请输入车牌查询";
      }
    }
  },
  methods: {
    getType(par, param) {
      var that = this;
      getCarTypeByParkingCode(par)
        .then(result => {
          if (result.status == "200" || result.status == 200) {
            if (result.data.carType == 2) {
              param['carType'] = result.data.carType;
              param['parkinglotId'] = result.data.parkinglotId;
              param['openId'] = this.scanAuthInfo.openid
              that.jumpToPay(param);
            } else {
              Notify({ type: "danger", message: "该车辆非黑名单车辆！" });
            }
          } else {
            Notify({ type: "danger", message: result.message });
          }
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    set_screen(wrap) {
      var s_height = document.body.clientHeight;
      var s_width = document.body.clientWidth;
      var proportion = s_height / s_width;
      if (proportion < 1.55) {
        var max_warp = document.querySelector(wrap);
        max_warp.style.height = "37.55" + "rem";
        document.ontouchmove = function(e) {};
      }
    },
    jumpToPay(param) {
      this.$router.push({
        path: "/arrears",
        query: param
      });
    },
    showKeyBoard() {
      this.show = true;
    },
    changeCarType() {
      this.newEnergyCar = true;
      this.carLength = 8;
    },
    showCnKeyBoard() {
      this.show = true;
      this.showCn = true;
      this.carlicenseArr = [];
    },
    findOrder() {
      let that = this;
      const token = "qrcode" + uuid(14);
      const payAppType = scanApp();
      const carlicense = that.carlicenseArr.join("");
      const type = that.recieveP[0];
      const parkingLotNo = that.recieveP[1];
      const payType = payAppType + type;

      if (payAppType == "other") {
        Notify({
          type: "danger",
          message: "暂不支持当前扫码软件"
        });
        return;
      }

      if (that.carlicenseArr.length < 7) {
        Notify({ type: "warning", message: "请输入正确的车牌" });
        return;
      }

      const cars = localStorage.getItem("historyCars");
      const historyCars = cars ? cars.split(",") : [];
      const myCars = [];
      that.myCars.map(item => {
        myCars.push(item.carLicense);
      });
      if (!historyCars.includes(carlicense) && !myCars.includes(carlicense)) {
        historyCars.length > 5 && historyCars.pop();
        historyCars.unshift(carlicense);
        localStorage.setItem("historyCars", historyCars.join(","));
        that.historyCars = historyCars;
      }
      const param = {
        token: token,
        parkinglotCode: parkingLotNo,
        unionId: that.unionId,
        openId: that.openId,
        carlicense: carlicense,
        iscode: true,
        payType:"3005"
      };
      var par = {
        parkinglotCode: parkingLotNo,
        license: carlicense
      };
      this.getType(par, param);
    },
    findHistoryCarOrder(e) {
      if (e.target.innerText.length == 8) {
        this.newEnergyCar = true;
        this.carLength = 8;
      }
      this.carlicenseArr = e.target.innerText.split("");
    },
    clearHistory() {
      this.historyCars = [];
      localStorage.removeItem("historyCars");
    },
    //键盘点击完成，收起键盘
    hideKeyBoard() {
      this.show = false;
    },
    //获取按键值
    getPressKeyValue(val) {
      let car = this.carlicenseArr.join("") + val;
      if (car.length > this.carLength) {
        window.console.log("车牌长度超出");
        return;
      }
      this.carlicenseArr = car.split("");
    },
    //切换中英文键盘
    changeKeyBoard() {
      this.showCn = !this.showCn;
    },
    //按下退格键
    delCarKeyPress() {
      let carArr = this.carlicenseArr;
      carArr.pop();
      this.carlicenseArr = carArr;
    },
    //按下清空键
    clearCarInput() {
      this.carlicenseArr = [];
      this.carLength = 7;
      this.newEnergyCar = false;
    }
  }
};
</script>

<style scoped lang="scss">
@search_height: 130px;
#scanInside {
  height: 100vh;
  background: #fff;
  .wrap {
    width: 100%;
    padding: 0 40px;
    padding-top: 80px;
    font-size: 32px;
    box-sizing: border-box;
    background: #fff;
    .search_title {
      font-family: PingFang-SC-Bold, PingFang-SC, sans-serif;
      text-align: center;
      height: 44px;
      font-size: 44px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      line-height: 44px;
    }
    .new_energy_car {
      height: 100px;
      border-radius: 10px;
      margin-top: 160px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      span {
        font-size: 44px;
        display: block;
        width: 64px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border-bottom: 2px solid #0cadb1;
      }
    }
    .search {
      font-family: PingFang-SC-Bold, PingFang-SC, sans-serif;
      margin-top: 80px;
      border-radius: 8px;
    }

    .history_search_wrap {
      font-family: PingFangSC-Regular, PingFang SC, sans-serif;
      box-sizing: border-box;
      margin-top: 20px;
      padding: 30px 0;
      font-size: 26px;
    }

    .mycars_search_wrap {
      font-family: PingFangSC-Regular, PingFang SC, sans-serif;
      box-sizing: border-box;
      font-size: 26px;
    }

    .history_search {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .historyCar_item {
        border-radius: 8px;
        width: 180px;
        height: 60px;
        line-height: 60px;
        background: #f6f6f6;
        color: #666666;
        text-align: center;
        margin: 10px 0;
        margin-right: 40px;
      }
    }

    .history_title {
      color: #000000;
      height: 60px;
      line-height: 60px;
      font-family: PingFangSC-Medium, PingFang SC, sans-serif;
      font-weight: 550;
    }

    .history_title div {
      float: right;
    }

    .history_title img {
      width: 32px;
      height: 32px;
      padding: 16px;
      vertical-align: middle;
    }

    .history_title span {
      display: inline-block;
      line-height: 60px;
      height: 60px;
    }
  }

  .placeholder {
    color: #ccc;
  }

  .black {
    color: rgb(20, 20, 20);
  }
}
</style>
