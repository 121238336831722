<!-- 绑定的车辆列表 -->
<template>
  <div v-if="showPage" class="container">
    <div class="yesnull">
      <img class="qfimg" src="@/assets/image/nottype.png" alt="" />
      <div class="qfdesc">对不起，未检测到车辆！</div>
      <div class="dhdesc">无法提供无牌车服务</div>
      <div class="alldescbtn" @click="accomplish">好</div>
    </div>
  </div>
</template>

<script>
import { closeWebviewEvent } from "@/utils/common";
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    return {
      showPage: true,
      orderlist: 10,
      isNull: true
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},

  filters: {},
  //方法集合
  methods: {
    accomplish() {
      closeWebviewEvent();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // pushHistory();
    window.addEventListener("popstate", closeWebviewEvent, false);

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  destroyed() {}
};
</script>
<style lang='scss' scoped>
.container {
  background: #ffffff;
  height: 100%;
  width: 100%;
  .yesnull {
    margin-top: 200px;
    text-align: center;
    padding-top: 20px;
    .qfimg {
      width: 300px;
    }
    .dhimg {
      width: 50px;
      height: 50px;
      padding-right: 10px;
    }
    .qfdesc {
      font-size: 28px;
      color: #535353;
      margin: 20px auto;
    }
    .dhdesc {
      font-size: 24px;
      color: #1b1b1b;
      opacity: 0.5;
      margin: 50px auto 20px auto;
    }
    .alldescbtn {
      width: 400px;
      height: 80px;
      line-height: 80px;
      margin: 40px auto auto auto;
      background: linear-gradient(-67deg, #26a0a5, #8de9c6);
      border-radius: 4px;
      color: #fff;
      font-size: 28px;
    }
  }
}
</style>